import React from "react";

export const colorizeSubstring = (content: string, stringToSearch: string) => {
  return (
    <span dangerouslySetInnerHTML={{
      __html: content.replaceAll(stringToSearch, `<span class="colorize">${stringToSearch}</span>`),
    }}
    />
  );
};
