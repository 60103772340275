import { SET_ACTIVE_SIDER, SET_HEADER_ACTIVE_KEY } from "./Types";

export interface MainState {
  main: {
    header: {
      activeKey: string;
    };
    activeSider: false | string;
  };
}

const initialState = {
  header: {
    activeKey: "home",
  },
  activeSider: false,
};

export function MainReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_HEADER_ACTIVE_KEY:
      return {
        ...state,
        header: {
          ...state.header,
          activeKey: action.payload,
        },
      };
    case SET_ACTIVE_SIDER:
      return {
        ...state,
        activeSider: action.payload,
      };
    default:
      return state;
  }
}
