/* eslint-disable max-len */

import React from "react";
import "./index.less";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { colorizeSubstring } from "../../utils/colorizeSubstring";

interface IProps {

}

const Rewards: React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <Layout className="rewards-wrapper">
      <div className="rewards-page-head">
        <h1>{colorizeSubstring(t("rewards_head_title"), t("colored_all"))}</h1>
        <p>
          {t("rewards_head_subtitle")}
        </p>
      </div>
      <div
        role="list"
        className="rewards-grid w-dyn-items"
      >
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/60f97e2f63735c32c302aba0_mercury.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Spend $1,000 with your debit card and receive $50 cash-back</div>
                <div className="div-block-194"><h1 className="reward-name">Mercury</h1></div>
                <div className="reward-summary">Banking built for startups all over the world.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/60ef2a32d01820abfe635d06_brex.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Earn up to 45k points ($450) with qualifying activities</div>
                <div className="div-block-194"><h1 className="reward-name">Brex</h1></div>
                <div className="reward-summary">All-in-one finance for every business.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61954957c1edd1f4d3b99949_stripe.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">The world’s most powerful and easy-to-use APIs</div>
                <div className="div-block-194"><h1 className="reward-name">Stripe</h1></div>
                <div className="reward-summary">Payments infrastructure for the internet</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61d4b4d75c8764732c0b2c93_notion.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$1,000 in credits off Notion Team or Enterprise Plans</div>
                <div className="div-block-194"><h1 className="reward-name">Notion</h1></div>
                <div className="reward-summary">All-in-one workspace</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/60ef2ab38239a083a9d77805_aws.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$5,000 in Credit</div>
                <div className="div-block-194"><h1 className="reward-name">Amazon Web Services</h1></div>
                <div className="reward-summary">Reliable, scalable, and inexpensive cloud computing services.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c0efbcfde6a12e1718453_carta.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Automated onboarding + white glove support</div>
                <div className="div-block-194"><h1 className="reward-name">Carta</h1></div>
                <div className="reward-summary">Equity Management Solutions - Equity Plans, Cap Tables &amp; 409A</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c1149114dd2238be74634_zendesk.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Up to $65k of Zendesk Suite &amp; Zendesk Sales CRM</div>
                <div className="div-block-194"><h1 className="reward-name">Zendesk</h1></div>
                <div className="reward-summary">Customer service software &amp; sales CRM.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616ee6d813af9c2a0f473e54_square.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$3,000 in Processing Credits</div>
                <div className="div-block-194"><h1 className="reward-name">Square</h1></div>
                <div className="reward-summary">Solutions For Your Small, Medium &amp; Large Business</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c0f754f1583029703d5d5_salesforce.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">14 day free trial and a 50% discount</div>
                <div className="div-block-194"><h1 className="reward-name">Salesforce</h1></div>
                <div className="reward-summary">The world`s #1 customer relationship management (CRM) platform.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c0ea2e8210c2765e5e247_deel.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">3 months free for contractors + 20% off employees for 12 months</div>
                <div className="div-block-194"><h1 className="reward-name">Deel</h1></div>
                <div className="reward-summary">Payroll &amp; Compliance for International Teams.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61fc0b6b28131654532602ac_remote.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">
                  50% Off on the first employee for 1 year + 2 months free for any additional
                  employee
                </div>
                <div className="div-block-194"><h1 className="reward-name">Remote</h1></div>
                <div className="reward-summary">Pay and manage full-time and contract workers worldwide.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c5cae8ed0a66679dca3a_firstbase-freshworks.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$10,000 in Credits</div>
                <div className="div-block-194"><h1 className="reward-name">Freshworks</h1></div>
                <div className="reward-summary">Modern and easy customer and employee experience software.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154d52399004f4b3e200145_firstbase-legalpad.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$500 in Work Visa Credits</div>
                <div className="div-block-194"><h1 className="reward-name">Legalpad</h1></div>
                <div className="reward-summary">Solving the immigration problem for startups.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/60ef2abe8239a07ba9d7780e_wise.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">1 free transfer</div>
                <div className="div-block-194"><h1 className="reward-name">Wise</h1></div>
                <div className="reward-summary">The cheap, fast way to send money abroad.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611eb52c1e25b75ce8e1beb2_techcrunch.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$50 Discount</div>
                <div className="div-block-194"><h1 className="reward-name">TechCrunch</h1></div>
                <div className="reward-summary">
                  Technology news and analysis with a focus on founders and startup teams.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c1215de32fa19c0c7b815_openphone.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">25% Discount for 1 Year</div>
                <div className="div-block-194"><h1 className="reward-name">OpenPhone</h1></div>
                <div className="reward-summary">Modern Business Phone for Startups and Small Businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/60ef2a88f51f231b0d2dfa87_airtable.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$2,000 in Credit</div>
                <div className="div-block-194"><h1 className="reward-name">Airtable</h1></div>
                <div className="reward-summary">Create apps that perfectly fit your team`s needs.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c11bde43624873fe360ae_bench.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">30% off for 3 Months</div>
                <div className="div-block-194"><h1 className="reward-name">Bench</h1></div>
                <div className="reward-summary">Take bookkeeping off your hands forever.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6137e684de246251f7624f42_ibmcloud.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$120,000 in Credit</div>
                <div className="div-block-194"><h1 className="reward-name">IBM Cloud</h1></div>
                <div className="reward-summary">Integrate with leading-edge technologies.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615493a965785559863d6786_firstbase-webflow.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Webflow</h1></div>
                <div className="reward-summary">The no-code platform for web design and development</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c10bdffbb17674f43b51d_gusto.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% to 20% Discount</div>
                <div className="div-block-194"><h1 className="reward-name">Gusto</h1></div>
                <div className="reward-summary">The best payroll for small business.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616eea6d8a9ac97d47790df6_trustpilot.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Product Reviews Worth $750</div>
                <div className="div-block-194"><h1 className="reward-name">Trustpilot</h1></div>
                <div className="reward-summary">Turn reviews into traffic, sales, and customer loyalty</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611c126f7557e80d12b7042e_pilot.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Pilot</h1></div>
                <div className="reward-summary">Bookkeeping and tax for startups and small businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611eb7626ac7dd71f8038223_justworks.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">2 Months Free</div>
                <div className="div-block-194"><h1 className="reward-name">Justworks</h1></div>
                <div className="reward-summary">Payroll, Benefits, Compliance, and HR for your employees.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61549f6832bdf372508f6c85_firstbase-1password-sq.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">25% Off On Teams Plan</div>
                <div className="div-block-194"><h1 className="reward-name">1Password</h1></div>
                <div className="reward-summary">
                  1Password is a privacy-focused password manager that keeps you safe
                  online.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61aa4e9b83d31e48633f87eb_firstbase-panther.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$20,000 Credit</div>
                <div className="div-block-194"><h1 className="reward-name">Panther</h1></div>
                <div className="reward-summary">Automated global payroll and compliance for remote teams.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c871af142f31eb2078df_firstbase-g.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Consultation</div>
                <div className="div-block-194"><h1 className="reward-name">GW Carter</h1></div>
                <div className="reward-summary">Receive tax services with international specialization.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6172f1f68a6212fff6d82d5b_15five.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">30% Off</div>
                <div className="div-block-194"><h1 className="reward-name">15Five</h1></div>
                <div className="reward-summary">Creating highly engaged, high-performing organizations</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e2359603a2334ea71e68_firstbase-s.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">15% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Software Ideas</h1></div>
                <div className="reward-summary">
                  Hand-picked, validated SaaS ideas delivered straight to your inbox.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154be451bc23c1eba46ac89_firstbase-bookmate.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off for 1 Month</div>
                <div className="div-block-194"><h1 className="reward-name">Bookmate</h1></div>
                <div className="reward-summary">An affordable accounting suite for your business.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61560dbc6dbd1cbad5acc4c8_firstbase-alcorn.png"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% Off on Course</div>
                <div className="div-block-194"><h1 className="reward-name">Alcorn Law</h1></div>
                <div className="reward-summary">A Silicon Valley immigration firm.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e04d270e4f0a60758c9b_firstbase-reply.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% to 30% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Reply</h1></div>
                <div className="reward-summary">Sales automation and sales engagement platform.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61553665d06ef76f6c49d8d6_firstbase-startup-falcon.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Startup Falcon</h1></div>
                <div className="reward-summary">Put your Startup on the Scale.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e9c6a59f00b4f796cd75_firstbase-tailor-brands.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">35% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Tailor Brands</h1></div>
                <div className="reward-summary">Brand design platform for small businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d562b0efecf7083a298c6_zyro.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">15% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Zyro</h1></div>
                <div className="reward-summary">Build a website or an online store.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61553925f0fb891452c94501_firstbase-snovio.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">More leads. More sales. More revenue.</div>
                <div className="div-block-194"><h1 className="reward-name">Snov.io</h1></div>
                <div className="reward-summary">Sales automation &amp; acceleration at scale.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615617f755f43ee3a8e19c8b_firstbase-crowdbotics.png"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$10 Off Hourly Rate and Plan Discounts</div>
                <div className="div-block-194"><h1 className="reward-name">Crowdbotics</h1></div>
                <div className="reward-summary">Build apps fast, no coding required.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154b9972fd7b3396987d981_firstbase-bigscoots.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">25% Off</div>
                <div className="div-block-194"><h1 className="reward-name">BigScoots</h1></div>
                <div className="reward-summary">Fully managed web, WordPress, VPS &amp; dedicated hosting.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616ef75ce3b1f2e47424ac6a_calendly.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Version of Calendly – forever.</div>
                <div className="div-block-194"><h1 className="reward-name">Calendly</h1></div>
                <div className="reward-summary">Free Online Appointment Scheduling Software</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6137e0dfdc17956e7a8b2029_demandcurve.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Demand Curve</h1></div>
                <div className="reward-summary">A world-class marketing course combined with expert advice.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e4201efdd4819e21610f_firstbase-slidebean.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Slidebean</h1></div>
                <div className="reward-summary">We help startups pitch investors.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616ef181688ef40eb4c790d1_payability.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$250 Sign-Up Bonus</div>
                <div className="div-block-194"><h1 className="reward-name">Payability</h1></div>
                <div className="reward-summary">
                  Funding and Cashflow Solutions for eCommerce and Marketplace Sellers
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615616811a022a3abf6893fb_firstbase-zoominfo.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Don’t just go to market, own your market.</div>
                <div className="div-block-194"><h1 className="reward-name">ZoomInfo</h1></div>
                <div className="reward-summary">
                  We have the precise information you need to reach your next customer,
                  convert your next lead, and close your next deal.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154ed26c184d6fbc7e63944_firstbase-userlist.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">30% Off for 3 Months</div>
                <div className="div-block-194"><h1 className="reward-name">Userlist</h1></div>
                <div className="reward-summary">Email Marketing Automation for SaaS.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c19e8661681c89728a8c_firstbase-bovino.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Business License Solutions</h1></div>
                <div className="reward-summary">Software, services, consulting, management, and research services.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d5587addc21973a8ae187_divvy.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Business Credit Card</div>
                <div className="div-block-194"><h1 className="reward-name">Divvy</h1></div>
                <div className="reward-summary">Expense Management &amp; Business Budgeting Software.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61553af8337cc26558401f2e_firstbase-trinet.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">30% Off Standard Administrative Fees</div>
                <div className="div-block-194"><h1 className="reward-name">TriNet</h1></div>
                <div className="reward-summary">
                  Professional employer organization that provides full-service HR
                  solutions.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154ec70664cf821a8a4a094_firstbase-t.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Tukamai Technologies</h1></div>
                <div className="reward-summary">Receive discounts for GSuite, Azure, and Office365.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c871af142f31eb2078df_firstbase-g.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$100 Discount</div>
                <div className="div-block-194"><h1 className="reward-name">GBS Tax &amp; Bookkeeping</h1></div>
                <div className="reward-summary">Purchase tax, business, or bookkeeping services.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615617456ccf4daf0ea6d546_firstbase-teilur.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$2,000 Waived On Setup Fees</div>
                <div className="div-block-194"><h1 className="reward-name">Teilur</h1></div>
                <div className="reward-summary">Hire the best tech talent in Latin America.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154f09a013a5002f767d1da_firstbase-winstar.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Consultation + Waived Setup Fees</div>
                <div className="div-block-194"><h1 className="reward-name">Winstar Payment</h1></div>
                <div className="reward-summary">Industry leading provider of payment services.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154d8dbe24aec1402266d43_firstbase-media-mobilize.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$250 in Credit</div>
                <div className="div-block-194"><h1 className="reward-name">Media Mobilize</h1></div>
                <div className="reward-summary">Empowering great publishers and advertisers.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154cd04afd0c73fe711bab2_firstbase-kodo.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Waived setup fees</div>
                <div className="div-block-194"><h1 className="reward-name">Kodo</h1></div>
                <div className="reward-summary">The corporate credit card Indian startups deserve.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61c28354ed33b79f4618fbf3_jpglegal.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$150 Discount</div>
                <div className="div-block-194"><h1 className="reward-name">JPG Legal</h1></div>
                <div className="reward-summary">File a trademark application.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154d6afab562b167c707ae9_firstbase-mangools.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Mangools</h1></div>
                <div className="reward-summary">Juicy SEO tools you will love.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c3408adeb08f7fe3181d_firstbase-c.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Consultation</div>
                <div className="div-block-194"><h1 className="reward-name">Callipari Law</h1></div>
                <div className="reward-summary">Legal and general counsel services.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e16d6cacd11b22aad719_firstbase-sendspark.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">6 Free Months of Pro</div>
                <div className="div-block-194"><h1 className="reward-name">Sendspark</h1></div>
                <div className="reward-summary">Record and share personalized videos.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d535ac385c21c8586c382_lattice.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">15% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Lattice</h1></div>
                <div className="reward-summary">Develop engaged &amp; high-performing teams.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615614f09f3876149fe16117_firstbase-delverise.png"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">35% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Delverise</h1></div>
                <div className="reward-summary">
                  Holistic product and growth agency for lean teams and growing startups.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154eb92e683fb6ebabe385d_firstbase-technerds.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Consultation + Website/SEO Audit</div>
                <div className="div-block-194"><h1 className="reward-name">TechNerds</h1></div>
                <div className="reward-summary">Full service digital marketing agency.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154dd394af5b25703b2737a_firstbase-pineapple-pitches.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$500 Off</div>
                <div className="div-block-194"><h1 className="reward-name">Pineapple Pitches</h1></div>
                <div className="reward-summary">We design beautiful pitch decks.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6155356e8eaa41d7fbef46e8_firstbase-accelity.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Accelity</h1></div>
                <div className="reward-summary">B2B SaaS marketing solutions.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611fe4d4b469e36d89e0773f_omnipresent.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% off first hire and 15% off all next hires for 12 months</div>
                <div className="div-block-194"><h1 className="reward-name">Omnipresent</h1></div>
                <div className="reward-summary">
                  Employ, Pay and Support your global teams across 150+ countries and
                  regions!
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154d5f21565e58dbafbae6a_firstbase-lokalise.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">3 months free on enterprise plan</div>
                <div className="div-block-194"><h1 className="reward-name">Lokalise</h1></div>
                <div className="reward-summary">A localization and translation software tool.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154edf4cc6f4d8f22024c69_firstbase-versoly.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">30% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Versoly</h1></div>
                <div className="reward-summary">Conversion focused website and landing page builder.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c0931565e5849cfb26b5_firstbase-bookupcrm.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% Off + 30 Day Free Trial</div>
                <div className="div-block-194"><h1 className="reward-name">BookupCRM</h1></div>
                <div className="reward-summary">The new way to manage your business.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e31c13157166dcdd1e8a_firstbase-sketchdeck.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$250 Voucher</div>
                <div className="div-block-194"><h1 className="reward-name">SketchDeck</h1></div>
                <div className="reward-summary">Design, delivered.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6137e89fa713140700ddcab8_adalo.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">25% Off for 3 Months</div>
                <div className="div-block-194"><h1 className="reward-name">Adalo</h1></div>
                <div className="reward-summary">Build your own no code app.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611eb4e2e7f8ed8658d4069e_aircall.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% off for 1 year</div>
                <div className="div-block-194"><h1 className="reward-name">Aircall</h1></div>
                <div className="reward-summary">
                  Aircall provides an integrated, easy to use, cloud-based phone solution.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615608c86711a9e8ce86858a_firstbase-neo.tax.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Claim up to $250k in Tax Credits</div>
                <div className="div-block-194"><h1 className="reward-name">neo.tax</h1></div>
                <div className="reward-summary">Automated taxes for startups.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615605d8b122280a1b33d46b_firstbase-limespot.png"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Fee waived for 3 months!</div>
                <div className="div-block-194"><h1 className="reward-name">LimeSpot</h1></div>
                <div className="reward-summary">Create personalized shopping experiences that convert.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154ded5d1f7503c47b807bb_firstbase-pn.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Consultation</div>
                <div className="div-block-194"><h1 className="reward-name">PN Lawyers</h1></div>
                <div className="reward-summary">Legal services for startups and small businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616ef43aced9b3d9cea40ff1_ampla.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Early Access To The Tool</div>
                <div className="div-block-194"><h1 className="reward-name">Ampla</h1></div>
                <div className="reward-summary">The modern line of credit built for brands</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154b4051404fa31f4c69be8_firstbase-signwell.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">3 Months Free</div>
                <div className="div-block-194"><h1 className="reward-name">SignWell</h1></div>
                <div className="reward-summary">
                  Make it so easy to sign your documents that it cuts turnaround time in
                  half.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615612f334e987823fa7a75e_firstbase-inai.png"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Of Use Until Reaching $100k in Payments</div>
                <div className="div-block-194"><h1 className="reward-name">inai</h1></div>
                <div className="reward-summary">Global payments without code.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d53e4be334818d1e3f79f_makerpad.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Makerpad</h1></div>
                <div className="reward-summary">Create software without writing code.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6137ecc30371b3820c45e04b_paro.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Tax Consultation</div>
                <div className="div-block-194"><h1 className="reward-name">Paro</h1></div>
                <div className="reward-summary">Access expert financial solutions.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616ef0083de2effbb7cde8bb_wefunder.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Discount</div>
                <div className="div-block-194"><h1 className="reward-name">Wefunder</h1></div>
                <div className="reward-summary">Invest in startups you love.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6155306a07ad7d86172f2ddd_firstbase-w.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% off 3 months &amp; 15% off recruiting plan</div>
                <div className="div-block-194"><h1 className="reward-name">WorkWester</h1></div>
                <div className="reward-summary">Boost your tech team and get top global talent.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d549c55e13604c31a0751_relay.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">U.S. Bank Account</div>
                <div className="div-block-194"><h1 className="reward-name">Relay Financial</h1></div>
                <div className="reward-summary">Banking designed for growing businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6156048554c807677278e864_firstbase-ontop.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">First Month Free + More</div>
                <div className="div-block-194"><h1 className="reward-name">Ontop</h1></div>
                <div className="reward-summary">Onboard, manage and pay your world-class team in seconds.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c44cf370574a9286d2da_firstbase-charthop.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% off first year</div>
                <div className="div-block-194"><h1 className="reward-name">ChartHop</h1></div>
                <div className="reward-summary">People analytics, visualized.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/618448b7bc60b28a7fa47daf_electric.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Get 10% Off Entire Contract</div>
                <div className="div-block-194"><h1 className="reward-name">Electric</h1></div>
                <div className="reward-summary">IT powers remote work. Electric powers IT.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61552facb8cf865096c38f44_firstbase-zirtual.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">2 Weeks Free or $200 Off</div>
                <div className="div-block-194"><h1 className="reward-name">Zirtual</h1></div>
                <div className="reward-summary">Virtual assistants you can`t go without.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6176cb11ac02e51d9506afe6_meetgeek.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">2 months free full access</div>
                <div className="div-block-194"><h1 className="reward-name">MeetGeek</h1></div>
                <div className="reward-summary">Maximize the productivity of your meetings!</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61553ba89363345f6404b647_firstbase-signeasy.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">5% Off Individual and 15% Off Business Plans</div>
                <div className="div-block-194"><h1 className="reward-name">SignEasy</h1></div>
                <div className="reward-summary">eSignatures for businesses that value simplicity.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d55208c3b187057a05f04_carrd.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">15% Off on Pro Plan</div>
                <div className="div-block-194"><h1 className="reward-name">Carrd</h1></div>
                <div className="reward-summary">Simple, responsive, one-page sites for pretty much anything.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61799a612b3dd55139f25b46_tapiocan.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$50 Off On 1st Year Annual Pro Plan</div>
                <div className="div-block-194"><h1 className="reward-name">Tapiocan</h1></div>
                <div className="reward-summary">Drag-and-Drop Website Builder - Affordable, Robust, No-Coding.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611fe452206b050697427d18_sproutsocial.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off On Annual Plans</div>
                <div className="div-block-194"><h1 className="reward-name">SproutSocial</h1></div>
                <div className="reward-summary">Social media management solutions.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c3408adeb08f7fe3181d_firstbase-c.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$20 Off</div>
                <div className="div-block-194"><h1 className="reward-name">ClickDissolve</h1></div>
                <div className="reward-summary">
                  Dissolve your LLC, corporation or nonprofit with just a few clicks.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615532f8671bfcd7b24817bc_firstbase-saaspirin.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$250 Off</div>
                <div className="div-block-194"><h1 className="reward-name">SaaSpirin</h1></div>
                <div className="reward-summary">Blog Writing for SaaS &amp; MarTech Businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154f292d4cc1a523fbb261d_firstbase-woodpecker.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">1 Month Free + 20% Lifetime Discount</div>
                <div className="div-block-194"><h1 className="reward-name">Woodpecker</h1></div>
                <div className="reward-summary">
                  Multichannel AI assistant for lead gen, sales &amp; recruitment
                  professionals
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c19e8661681c89728a8c_firstbase-bovino.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Off Trademark</div>
                <div className="div-block-194"><h1 className="reward-name">Bovino Law Group</h1></div>
                <div className="reward-summary">Immigration &amp; international business attorneys.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154bfa80dd75dc81c03a1a1_firstbase-bookskeep.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Bookskeep</h1></div>
                <div className="reward-summary">We help e-commerce sellers grow profits.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61553a17f58064b84e0e8845_firstbase-f.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Get 40% Off Any Annual Plan</div>
                <div className="div-block-194"><h1 className="reward-name">Foundersuite</h1></div>
                <div className="reward-summary">A better way to raise capital.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154d36734e43a0950005b97_firstbase-latka.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Latka SaaS Hackers </h1></div>
                <div className="reward-summary">
                  The leading private community for SaaS CEOs, founders and investors.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611eb56d8c05643aa41c10cd_shopision.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% Off for 6 Months</div>
                <div className="div-block-194"><h1 className="reward-name">Shopision</h1></div>
                <div className="reward-summary">#1 on-demand Shopify web design and support service.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61560edd2205eb1a47320b06_firstbase-spocket.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">
                  Spocket allows you to easily start dropshipping top products from US and EU
                  suppliers.
                </div>
                <div className="div-block-194"><h1 className="reward-name">Spocket</h1></div>
                <div className="reward-summary">Best Dropshipping Suppliers for US + EU Products</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e2359603a2334ea71e68_firstbase-s.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">30 Days Free + 10% One-time Discount</div>
                <div className="div-block-194"><h1 className="reward-name">ShipCube</h1></div>
                <div className="reward-summary">Software and services for eCommerce brands.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154bcf399004f47581f6c51_firstbase-savvi.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Up to $1,000 in credit</div>
                <div className="div-block-194"><h1 className="reward-name">Savvi Legal</h1></div>
                <div className="reward-summary">The legal hub for founders and attorneys.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615607fc844b455f9e489f29_firstbase-mailshake.png"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Mailshake</h1></div>
                <div className="reward-summary">Easy-to-use sales engagement platform.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611fe70fbaefcf986b12904d_wing.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Discounts from $250 to $1,000</div>
                <div className="div-block-194"><h1 className="reward-name">Wing Assistant</h1></div>
                <div className="reward-summary">The world`s best assistant for business.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c3408adeb08f7fe3181d_firstbase-c.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$200 discount off 1st month</div>
                <div className="div-block-194"><h1 className="reward-name">Cronosnow</h1></div>
                <div className="reward-summary">Less stress, more time, more profits.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154ef25664cf803e6a4b173_firstbase-vertofx.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">1 Free Transaction up to $15,000</div>
                <div className="div-block-194"><h1 className="reward-name">VertoFX</h1></div>
                <div className="reward-summary">B2B Currency Exchange Marketplace.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6184455d073e2cc471970a72_MentorCruise.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Discount Of Your First Session</div>
                <div className="div-block-194"><h1 className="reward-name">MentorCruise</h1></div>
                <div className="reward-summary">Get advice from top startup mentors</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6156099e5dc6ca5a57d4b6f9_firstbase-clearco.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$500 Cash Bonus On Top ClearCo Investment.</div>
                <div className="div-block-194"><h1 className="reward-name">ClearCo</h1></div>
                <div className="reward-summary">Growth capital for online businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154d9da270e4f382b75634a_firstbase-o.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$50 Off</div>
                <div className="div-block-194"><h1 className="reward-name">Online Taxman</h1></div>
                <div className="reward-summary">CPA services worldwide.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616eef1cb651f41e3792b36f_founderscard.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">6-Months Free FoundersCard Memberships</div>
                <div className="div-block-194"><h1 className="reward-name">FoundersCard</h1></div>
                <div className="reward-summary">Extraordinary benefits for entrepreneurs</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154dc88c83f222b319ee131_firstbase-pickfu.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% Off</div>
                <div className="div-block-194"><h1 className="reward-name">PickFu</h1></div>
                <div className="reward-summary">Split testing, optimization, and instant consumer research.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c9d4aac8e047c768007f_firstbase-growth-division.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">25% Off for 1 Month</div>
                <div className="div-block-194"><h1 className="reward-name">Growth Division</h1></div>
                <div className="reward-summary">Growth marketing experts for startups.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6137e172a71314f2e7dd982a_iubenda.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Iubenda</h1></div>
                <div className="reward-summary">Get help with legal requirements for your business.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/617999218d23218fc45b27bd_officehours.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$300 Booking Credit</div>
                <div className="div-block-194"><h1 className="reward-name">Office Hours</h1></div>
                <div className="reward-summary">Book top experts in tech and healthcare for advice and feedback.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611eb7172eb75fe10940c265_sonetel.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$10 in Credit</div>
                <div className="div-block-194"><h1 className="reward-name">Sonetel</h1></div>
                <div className="reward-summary">Communication services for entrepreneurs.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154eaa7587fa665268afc12_firstbase-taskdrive.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">10% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Taskdrive</h1></div>
                <div className="reward-summary">B2B Lead Generation Service for Sales Teams</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154c69234e43a5575ffff26_firstbase-glockapps.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">GlockApps</h1></div>
                <div className="reward-summary">Expert email deliverability test, checker, software &amp; tools.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154e6503a833a0a889c1773_firstbase-startups.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">25% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Startups</h1></div>
                <div className="reward-summary">Courses, expert advice &amp; software for startup founders.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/618446f5a73642ffec8f9d52_swipesum.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Unlimited Consultations For 1 Year</div>
                <div className="div-block-194"><h1 className="reward-name">Swipesum</h1></div>
                <div className="reward-summary">
                  Discover the best credit card processing solution for your unique
                  business
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61561403213d8322afe1267f_firstbase-northone.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">3 Months Free</div>
                <div className="div-block-194"><h1 className="reward-name">NorthOne</h1></div>
                <div className="reward-summary">Small business banking made simple.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6137e91ffc077a2021b51b54_freshbooks.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">60-day Free Trial</div>
                <div className="div-block-194"><h1 className="reward-name">FreshBooks</h1></div>
                <div className="reward-summary">Invoice and Accounting Software for Small Businesses.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/616ef939c1854a2fbeb1a908_upstack.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$2,000 Discount</div>
                <div className="div-block-194"><h1 className="reward-name">Upstack</h1></div>
                <div className="reward-summary">Hire Skilled Remote Developers</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d5437f3900bdb41dd0cea_baremetrics.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% off for 1 Month</div>
                <div className="div-block-194"><h1 className="reward-name">Baremetrics</h1></div>
                <div className="reward-summary">
                  Subscription Analytics &amp; Insights for Stripe, Braintree,
                  Recurly &amp; more!
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615537fc560510157d9faede_firstbase-vantage.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">First $2,500 per month of cloud costs tracked for free</div>
                <div className="div-block-194"><h1 className="reward-name">Vantage</h1></div>
                <div className="reward-summary">Manage your cloud costs.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/615531b996eda72f937fc371_firstbase-shareworks.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% off for private company solutions</div>
                <div className="div-block-194"><h1 className="reward-name">Shareworks</h1></div>
                <div className="reward-summary">Equity Compensation Solutions.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154efde270e4f2cbb76017d_firstbase-weekendclub.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">15% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Weekend Club</h1></div>
                <div className="reward-summary">The weekend co-working club for bootstrappers.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6156039223942b5b71aa620d_firstbase-workbox.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">
                  Get $1,000 off Workspace and Exclusive Access to our Accelerator Network.
                </div>
                <div className="div-block-194"><h1 className="reward-name">WorkBox</h1></div>
                <div className="reward-summary">
                  A coworking accelerator that provides bold companies with the resources to
                  build faster, smarter &amp; together.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6137e03f0f34c9c457462235_passright.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Consultation</div>
                <div className="div-block-194"><h1 className="reward-name">PassRight</h1></div>
                <div className="reward-summary">The O-1 Visa Company.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61560719b122289bd833dc3d_firstbase-fomo.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">2 Months of Free Access</div>
                <div className="div-block-194"><h1 className="reward-name">Fomo</h1></div>
                <div className="reward-summary">Social Proof Marketing Platform.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611eb5d0809f84c9b89963f9_payoneer.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$50 Cashback, Zero Incoming Fees, 70% Savings</div>
                <div className="div-block-194"><h1 className="reward-name">Payoneer</h1></div>
                <div className="reward-summary">
                  The commerce technology company powering payments and growth for the new
                  global economy.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/611d5677c95b3106ba51366c_audiogo.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free $300 Audio Campaign</div>
                <div className="div-block-194"><h1 className="reward-name">AudioGo</h1></div>
                <div className="reward-summary">Audio advertising made simple.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6154f3847406d66a87d93d8d_firstbase-zeroqode.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">20% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Zeroqode</h1></div>
                <div className="reward-summary">No-code app templates, courses, plugins and more.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/618d4c7f67bc156ff5b6cdf8_infobrandz.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">3 Day Trial, 30 Minute Consultation and 10% Off</div>
                <div className="div-block-194"><h1 className="reward-name">Infobrandz</h1></div>
                <div className="reward-summary">Get your brand prepared for the cut-through competition</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/6192940fd4e1216598c34136_grapelaw.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">Free Consultation + %10 OFF For All Services</div>
                <div className="div-block-194"><h1 className="reward-name">GrapeLaw</h1></div>
                <div className="reward-summary">Immigration and Business Services for Startups</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/619ea2fe6c45875603a60cab_outshore.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">
                  $500 off first employee + 1 month free lead generation specialist (with a
                  team of at least 3 people)
                </div>
                <div className="div-block-194"><h1 className="reward-name">Outshore.io</h1></div>
                <div className="reward-summary">
                  Transition from traditional hiring to digital nearshore outsourcing
                  solutions.
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61ae7d36da104e3052bea035_firstbase-activatedscale.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">50% off the transaction fee in the first month.</div>
                <div className="div-block-194"><h1 className="reward-name">Activated Scale</h1></div>
                <div className="reward-summary">Connect your company with part-time sales talent.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61ae7d928ce3c61efd3705dc_termly.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">25% off</div>
                <div className="div-block-194"><h1 className="reward-name">Termly</h1></div>
                <div className="reward-summary">Easy way to comply with data compliance regulations.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61c284ea31c35e141bc81ce2_fundraisingradio.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">70% off the course</div>
                <div className="div-block-194"><h1 className="reward-name">Fundraising Radio</h1></div>
                <div className="reward-summary">Course on fundraising for early stage startups.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61d4b30e53fb3be48718dbbe_apploye.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">40% discount on all monthly plans</div>
                <div className="div-block-194"><h1 className="reward-name">Apploye</h1></div>
                <div className="reward-summary">
                  Remote employee productivity software for small to large businesses
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61d4b36181febf5d056c2c68_reel.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">1 month of Unlimited graphic design for free, and 25% off any plan</div>
                <div className="div-block-194"><h1 className="reward-name">Reel</h1></div>
                <div className="reward-summary">Unlimited graphic design for startups</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61d8a7f5400573b97115c2a1_perchpeek.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">$500 Off</div>
                <div className="div-block-194"><h1 className="reward-name">PerchPeek</h1></div>
                <div className="reward-summary">Help your employees relocate.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61df59874e1ac408bb7c3259_pdffiller.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">67% off on annual plans</div>
                <div className="div-block-194"><h1 className="reward-name">pdfFiller</h1></div>
                <div className="reward-summary">Create, edit and eSign static PDFs online.</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61e7085cae50445ddda0fa35_studentbuild.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">30% off your first match</div>
                <div className="div-block-194"><h1 className="reward-name">StudentBuild</h1></div>
                <div className="reward-summary">Mentor vetted apprentices as they help you grow your business</div>
              </div>
            </div>
          </a>
        </div>
        <div
          role="listitem"
          className="reward-card w-dyn-item"
        >
          <a
            className="reward-link w-inline-block"
          >
            <div className="reward-left">
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/61e709b63680f90f6524a956_bvrcloud.svg"
                loading="lazy"
                alt=""
                className="reward-img"
              />
              <div>
                <div className="reward-promo">12 months FREE</div>
                <div className="div-block-194"><h1 className="reward-name">BVR Cloud</h1></div>
                <div className="reward-summary">A top alternative to AWS, Azure &amp; GCP</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Rewards;
