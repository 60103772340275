import React from "react";
import "./index.less";
import { PushpinOutlined } from "@ant-design/icons";

interface IProps {
  icon?: any;
  title?: string;
  location?: string;
}

const PinLocation: React.FC<IProps> = ({
  icon = undefined, title, location,
}) => {
  return (
    <div className="pin-location">
      <div className="icon-wrap">
        {icon || <PushpinOutlined />}
      </div>
      <div className="content-wrap">
        {title && <p className="title">{title}</p>}
        {location && <p className="location">{location}</p>}
      </div>
    </div>
  );
};

export default PinLocation;
