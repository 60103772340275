import React from "react";
import "./index.less";

interface IProps {
  header?: JSX.Element | string;
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  extra?: JSX.Element | string;
}

const SectionBlock: React.FC<IProps> = ({
  header, title, content, extra,
}) => {
  return (
    <>
      {header && <div className="section-block-header">{header}</div>}
      <div className="section-block">
        <div>
          {title && <div className="title">{title}</div>}
          {content && <div className="content">{content}</div>}
        </div>
        {extra && <div className="extra">{extra}</div>}
      </div>
    </>
  );
};

export default SectionBlock;
