import React from "react";

export const repeat = (dataArray: any[], Component: any) => {
  return (
    <div className="repeated-component">
      {dataArray.map((dt, index) => {
        return React.cloneElement(Component, { ...dt, key: index });
      })}
    </div>
  );
};
