import React, {
  useEffect, useRef, useState,
} from "react";
import "./index.less";
import {
  Col, Layout, Row,
} from "antd";
import Globe from "react-globe.gl";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";
import BrandButton from "../../components/BrandButton";
import SectionBlock from "../../components/SectionBlock";
import { colorizeSubstring } from "../../utils/colorizeSubstring";
import BlockHeader from "../../components/BlockHeader";
import PinLocation from "../../components/PinLocation";
import { Thtml } from "../../utils/thtml";
import { repeat } from "../../utils/repeat";
import InformationBox from "../../components/InformationBox";
import IRS from "../../assets/images/IRS.png";
import BankCards from "../../assets/images/BankCards.png";
import IconSlider from "../../components/IconSlider";
import IconSlider11 from "../../assets/images/slider/slider1_1.svg";
import IconSlider12 from "../../assets/images/slider/slider1_2.svg";
import IconSlider13 from "../../assets/images/slider/slider1_3.svg";
import IconSlider14 from "../../assets/images/slider/slider1_4.svg";
import IconSlider15 from "../../assets/images/slider/slider1_5.svg";
import IconSlider16 from "../../assets/images/slider/slider1_6.svg";
import IconSlider17 from "../../assets/images/slider/slider1_7.svg";
import IconSlider18 from "../../assets/images/slider/slider1_8.svg";
import IconSlider19 from "../../assets/images/slider/slider1_9.svg";
import IconSlider110 from "../../assets/images/slider/slider1_10.svg";
import IconSlider111 from "../../assets/images/slider/slider1_11.svg";
import IconSlider112 from "../../assets/images/slider/slider1_12.svg";
import IconSlider113 from "../../assets/images/slider/slider1_13.svg";
import IconSlider21 from "../../assets/images/slider/slider2_1.svg";
import IconSlider22 from "../../assets/images/slider/slider2_2.svg";
import IconSlider23 from "../../assets/images/slider/slider2_3.svg";
import IconSlider24 from "../../assets/images/slider/slider2_4.svg";
import IconSlider25 from "../../assets/images/slider/slider2_5.svg";
import IconSlider26 from "../../assets/images/slider/slider2_6.svg";
import IconSlider27 from "../../assets/images/slider/slider2_7.svg";
import IconSlider28 from "../../assets/images/slider/slider2_8.svg";
import IconSlider29 from "../../assets/images/slider/slider2_9.svg";
import IconSlider210 from "../../assets/images/slider/slider2_10.svg";
import IconSlider211 from "../../assets/images/slider/slider2_11.svg";
import IconSlider212 from "../../assets/images/slider/slider2_12.svg";
import IconSlider213 from "../../assets/images/slider/slider2_13.svg";
import IconSlider31 from "../../assets/images/slider/slider3_1.svg";
import IconSlider32 from "../../assets/images/slider/slider3_2.svg";
import IconSlider33 from "../../assets/images/slider/slider3_3.svg";
import IconSlider34 from "../../assets/images/slider/slider3_4.svg";
import IconSlider35 from "../../assets/images/slider/slider3_5.svg";
import IconSlider36 from "../../assets/images/slider/slider3_6.svg";
import IconSlider37 from "../../assets/images/slider/slider3_7.svg";
import IconSlider38 from "../../assets/images/slider/slider3_8.svg";
import IconSlider39 from "../../assets/images/slider/slider3_9.svg";
import IconSlider310 from "../../assets/images/slider/slider3_10.svg";
import IconSlider311 from "../../assets/images/slider/slider3_11.svg";
import IconSlider312 from "../../assets/images/slider/slider3_12.svg";
import IconSlider313 from "../../assets/images/slider/slider3_13.svg";

interface IProps{

}

const GLOBE_HEIGHT = 800;
const GLOBE_COEF = 1.75;

const Home:React.FC<IProps> = () => {
  const { t } = useTranslation();
  const globeRef = useRef<any>();
  const globeContainerRef = useRef<any>();
  const [globeWidth, setGlobeWidth] = useState<any>(globeContainerRef?.current?.clientWidth);
  const N = 15;
  // @ts-ignore
  const arcsData = [...Array(N).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: ["white", "#2871cc"],
  }));

  const changeGlobeDimms = useDebouncedCallback(() => {
    setGlobeWidth(globeContainerRef?.current?.clientWidth);
  }, 300);

  useEffect(() => {
    globeRef.current.controls().autoRotate = true;
    globeRef.current.controls().autoRotateSpeed = 0.3;
    globeRef.current.controls().enableZoom = false;
    window.addEventListener("resize", changeGlobeDimms);

    return () => {
      window.removeEventListener("resize", changeGlobeDimms);
    };
  }, [changeGlobeDimms]);

  const introStep2ExtraData = [
    { title: t("intro_step_2_extra_1") },
    { title: t("intro_step_2_extra_2") },
    { title: t("intro_step_2_extra_3") },
    { title: t("intro_step_2_extra_4") },
    { title: t("intro_step_2_extra_5") },
    { title: t("intro_step_2_extra_6") },
    { title: t("intro_step_2_extra_7") },
    { title: t("intro_step_2_extra_8") },
    { title: t("intro_step_2_extra_9") },
    { title: t("intro_step_2_extra_10") },
  ];

  return (
    <Layout style={{ minHeight: GLOBE_HEIGHT / GLOBE_COEF }}>
      <div
        ref={globeContainerRef}
        className="globe-section"
        style={{
          pointerEvents: "none", height: GLOBE_HEIGHT / GLOBE_COEF, overflow: "hidden",
        }}
      >
        <Globe
          ref={globeRef}
          backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-dark.jpg"
          arcsData={arcsData}
          arcColor="color"
          arcDashLength={() => Math.random()}
          arcDashGap={() => Math.random()}
          arcDashAnimateTime={() => 3000}
          width={globeWidth}
          height={GLOBE_HEIGHT}
          waitForGlobeReady={false}

        />
      </div>
      <div
        className="globe-content"
        style={{ maxHeight: GLOBE_HEIGHT / GLOBE_COEF, overflow: "hidden" }}
      >
        <h1>{colorizeSubstring(t("website_heading"), t("colored_business"))}</h1>
        <p>{t("access_the_greatest")}</p>
        <BrandButton
          title={t("get_started")}
          href="/get-started"
          icon={<RightOutlined style={{ fontSize: 16 }} />}
        />
      </div>
      <div className="intro-section">
        <SectionBlock
          title={<h2>{t("all_in_one_tool")}</h2>}
          content={(
            <span>
              {t("intro_content_1")}
              <br />
              {t("intro_content_2")}
            </span>
          )}
        />
        <SectionBlock
          title={(
            <BlockHeader
              count={1}
              title={t("intro_step_1")}
            />
          )}
          content={t("intro_step_1_content")}
          extra={(
            <div className="step-1-extra-wrapper">
              <PinLocation
                title={t("your_business")}
                location={t("your_business_loc_1")}
              />
              <PinLocation
                title={t("your_business")}
                location={t("your_business_loc_2")}
              />
            </div>
          )}
        />
        <SectionBlock
          title={(
            <BlockHeader
              count={2}
              title={t("intro_step_2")}
            />
          )}
          content={t("intro_step_2_content")}
          extra={(
            <div className="step-2-extra-wrapper">
              {repeat(introStep2ExtraData, <InformationBox />)}
            </div>
          )}
        />
        <div className="step-3-and-4">
          <Row
            gutter={[36, 0]}
          >
            <Col
              span={24}
              md={24}
              lg={12}
            >
              <SectionBlock
                title={(
                  <BlockHeader
                    count={3}
                    title={t("intro_step_3")}
                  />
                )}
                content={Thtml("intro_step_3_content")}
                extra={(
                  <div className="step-1-extra-wrapper">
                    <img
                      src={IRS}
                      alt="IRSNumber"
                    />
                  </div>
                )}
              />
            </Col>
            <Col
              span={24}
              md={24}
              lg={12}
            >
              <SectionBlock
                title={(
                  <BlockHeader
                    count={4}
                    title={t("intro_step_4")}
                  />
                )}
                content={t("intro_step_4_content")}
                extra={(
                  <div className="step-4-extra-wrapper">
                    <img
                      src={BankCards}
                      alt="Bank Account Methods"
                    />
                  </div>
                )}
              />
            </Col>
          </Row>
        </div>
        <div className="centered">
          <BrandButton
            title={t("learn_how_it_works")}
            href="/how-it-works"
            icon={<RightOutlined style={{ fontSize: 16 }} />}
          />
        </div>
      </div>
      <div className="slider-section">
        <h2>{colorizeSubstring(t("more_than_just"), t("colored_more"))}</h2>
        <p>
          {t("choosing_gitex_grants")}
          <br />
          {t("over_150k")}
        </p>
        <div className="slider">
          <IconSlider
            speed={2}
            size={70}
            list={[IconSlider11, IconSlider12, IconSlider13, IconSlider14, IconSlider15, IconSlider16, IconSlider17,
              IconSlider18, IconSlider19, IconSlider110, IconSlider111, IconSlider112, IconSlider113,
              IconSlider11, IconSlider12, IconSlider13, IconSlider14, IconSlider15, IconSlider16, IconSlider17,
              IconSlider18, IconSlider19, IconSlider110, IconSlider111, IconSlider112, IconSlider113,
              IconSlider11, IconSlider12, IconSlider13, IconSlider14, IconSlider15, IconSlider16, IconSlider17,
              IconSlider18, IconSlider19, IconSlider110, IconSlider111, IconSlider112, IconSlider113,
              IconSlider11, IconSlider12, IconSlider13, IconSlider14, IconSlider15, IconSlider16, IconSlider17,
              IconSlider18, IconSlider19, IconSlider110, IconSlider111, IconSlider112, IconSlider113,
              IconSlider11, IconSlider12, IconSlider13, IconSlider14, IconSlider15, IconSlider16, IconSlider17,
              IconSlider18, IconSlider19, IconSlider110, IconSlider111, IconSlider112, IconSlider113,
              IconSlider11, IconSlider12, IconSlider13, IconSlider14, IconSlider15, IconSlider16, IconSlider17,
              IconSlider18, IconSlider19, IconSlider110, IconSlider111, IconSlider112, IconSlider113]}
          />
          <IconSlider
            speed={2}
            size={70}
            shiftX={30}
            list={[IconSlider21, IconSlider22, IconSlider23, IconSlider24, IconSlider25, IconSlider26, IconSlider27,
              IconSlider28, IconSlider29, IconSlider210, IconSlider211, IconSlider212, IconSlider213,
              IconSlider21, IconSlider22, IconSlider23, IconSlider24, IconSlider25, IconSlider26, IconSlider27,
              IconSlider28, IconSlider29, IconSlider210, IconSlider211, IconSlider212, IconSlider213,
              IconSlider21, IconSlider22, IconSlider23, IconSlider24, IconSlider25, IconSlider26, IconSlider27,
              IconSlider28, IconSlider29, IconSlider210, IconSlider211, IconSlider212, IconSlider213,
              IconSlider21, IconSlider22, IconSlider23, IconSlider24, IconSlider25, IconSlider26, IconSlider27,
              IconSlider28, IconSlider29, IconSlider210, IconSlider211, IconSlider212, IconSlider213,
              IconSlider21, IconSlider22, IconSlider23, IconSlider24, IconSlider25, IconSlider26, IconSlider27,
              IconSlider28, IconSlider29, IconSlider210, IconSlider211, IconSlider212, IconSlider213,
              IconSlider21, IconSlider22, IconSlider23, IconSlider24, IconSlider25, IconSlider26, IconSlider27,
              IconSlider28, IconSlider29, IconSlider210, IconSlider211, IconSlider212, IconSlider213]}
          />
          <IconSlider
            speed={2}
            size={70}
            list={[IconSlider31, IconSlider32, IconSlider33, IconSlider34, IconSlider35, IconSlider36, IconSlider37,
              IconSlider38, IconSlider39, IconSlider310, IconSlider311, IconSlider312, IconSlider313,
              IconSlider31, IconSlider32, IconSlider33, IconSlider34, IconSlider35, IconSlider36, IconSlider37,
              IconSlider38, IconSlider39, IconSlider310, IconSlider311, IconSlider312, IconSlider313,
              IconSlider31, IconSlider32, IconSlider33, IconSlider34, IconSlider35, IconSlider36, IconSlider37,
              IconSlider38, IconSlider39, IconSlider310, IconSlider311, IconSlider312, IconSlider313,
              IconSlider31, IconSlider32, IconSlider33, IconSlider34, IconSlider35, IconSlider36, IconSlider37,
              IconSlider38, IconSlider39, IconSlider310, IconSlider311, IconSlider312, IconSlider313,
              IconSlider31, IconSlider32, IconSlider33, IconSlider34, IconSlider35, IconSlider36, IconSlider37,
              IconSlider38, IconSlider39, IconSlider310, IconSlider311, IconSlider312, IconSlider313,
              IconSlider31, IconSlider32, IconSlider33, IconSlider34, IconSlider35, IconSlider36, IconSlider37,
              IconSlider38, IconSlider39, IconSlider310, IconSlider311, IconSlider312, IconSlider313]}
          />
        </div>
      </div>
      <div className="after-slider-section">
        <h2>{colorizeSubstring(t("your_success"), t("colored_your"))}</h2>
        <p>
          {t("start_growing_in_us")}
          <br />
          {t("make_dreams_come_true")}
        </p>
      </div>
      <div className="before-footer-section">
        <h1>{colorizeSubstring(t("access_greatest_startup"), t("access_greatest_startup"))}</h1>
        <p className="before-footer-1">{t("before_footer_1")}</p>
        <p className="before-footer-2">{t("before_footer_2")}</p>
        <p className="before-footer-3">{t("before_footer_3")}</p>
        <p className="before-footer-4">{t("before_footer_4")}</p>
        <p className="before-footer-5">{t("before_footer_5")}</p>
        <p className="before-footer-6">{t("before_footer_6")}</p>
        <p className="before-footer-7">{t("before_footer_7")}</p>
      </div>
    </Layout>
  );
};

export default Home;
