import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./resources";

const lng = localStorage.getItem("lng") || "en";

const resources = {
  en: {
    translation: translations.en,
  },
};

const initI18N = ():void => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export default initI18N;
