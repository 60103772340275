import "./index.less";
import React from "react";
import {
  Badge, Collapse, Layout,
} from "antd";
import { useTranslation } from "react-i18next";
import EN_FAQ from "./_en_faq.json";
import { colorizeSubstring } from "../../utils/colorizeSubstring";

interface IProps{

}

const { Panel } = Collapse;

const FAQ:React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <Layout className="faq-wrapper">
      <div className="faq-page-head">
        <h1>{colorizeSubstring(t("faq_head_title"), t("colored_gitex_america"))}</h1>
      </div>
      <Collapse className="faq-collapse">
        {EN_FAQ.faq_categories.map((cat, index: number) => {
          return (
            <Panel
              header={(
                <div className="flex-panel-title">
                  <b>{cat.title}</b>
                  {cat.subtitle.length > 0 && (
                  <small>
                    {" "}
                    (
                    {cat.subtitle}
                    )
                  </small>
                  )}
                </div>
              )}
              extra={(
                <Badge
                  count={cat.count}
                />
            )}
              key={index}
            >
              <Collapse
                accordion
                ghost
              >
                {cat.faq.map((faq, faqIndex) => {
                  return (
                    <Panel
                      header={faq.Q}
                      key={faqIndex}
                    >
                      <div dangerouslySetInnerHTML={{ __html: faq.A }} />
                      {faq.hasImage && faq.hasImage?.split(",")?.map((image: any) => (
                        <img
                          src={`/images/${image}`}
                          alt={image}
                          className="faq-image"
                        />
                      ))}
                    </Panel>
                  );
                })}
              </Collapse>
            </Panel>
          );
        })}
      </Collapse>
    </Layout>
  );
};

export default FAQ;
