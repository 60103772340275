import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import GitexLogo from "../../assets/logo.svg";
import { setHeaderActiveKey } from "../../store/Actions";

interface IProps{

}
const Header:React.FC<IProps> = ({}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [active, setActive] = useState("home");

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setHeaderActiveKey("home");
        setActive("home"); break;
      case "/about":
        setHeaderActiveKey("about");
        setActive("about"); break;
      case "/how-it-works":
        setHeaderActiveKey("how_it_works");
        setActive("how_it_works"); break;
      case "/pricing":
        setHeaderActiveKey("pricing");
        setActive("pricing"); break;
      case "/rewards":
        setHeaderActiveKey("rewards");
        setActive("rewards"); break;
      case "/get-started":
        setHeaderActiveKey("get_started");
        setActive("get_started"); break;
      case "/faq":
        setHeaderActiveKey("faq");
        setActive("faq"); break;
      case "/contact":
        setHeaderActiveKey("contact");
        setActive("contact"); break;
      case "/terms":
        setHeaderActiveKey("terms");
        setActive("terms"); break;
      default:
        setHeaderActiveKey("home");
        setActive("home");
    }
  }, [dispatch, location]);

  return (
    <div className="header">
      <div className="header-content-wrapper">
        <Link to="/">
          <img
            src={GitexLogo}
            alt="Gitex Inc."
          />
        </Link>
        <Menu
          className="header-menu"
          selectedKeys={[active]}
          mode="horizontal"
        >
          <Menu.Item key="about">
            <Link to="/about">{t("about")}</Link>
          </Menu.Item>
          <Menu.Item key="how_it_works">
            <Link to="/how-it-works">{t("how_it_works")}</Link>
          </Menu.Item>
          <Menu.Item key="pricing">
            <Link to="/pricing">{t("pricing")}</Link>
          </Menu.Item>
          <Menu.Item key="rewards">
            <Link to="/rewards">{t("rewards")}</Link>
          </Menu.Item>
          <Menu.Item key="get_started">
            <Link to="/get-started">{t("get_started")}</Link>
          </Menu.Item>
          <Menu.Item key="faq">
            <Link to="/faq">{t("faq")}</Link>
          </Menu.Item>
          {/* <Menu.Item key="contact">
            <Link to="/contact">{t("contact")}</Link>
          </Menu.Item>
          <Menu.Item key="terms">
            <Link to="/terms">{t("privacy_policy")}</Link>
          </Menu.Item> */}
        </Menu>
      </div>
    </div>
  );
};

export default Header;
