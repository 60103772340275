import React from "react";
import initI18N from "./i18n";
import "./assets/main.less";
import AppRouter from "./router/AppRouter";

initI18N();

const App:React.FC = () => {
  return (
    <AppRouter />
  );
};

export default App;
