import {
  applyMiddleware, combineReducers, compose, createStore,
} from "redux";
import thunk from "redux-thunk";
import { MainReducer } from "./Reducers";

// prettier-ignore
const composeEnhancers =
  // @ts-ignore
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    // @ts-ignore
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const reducers = combineReducers({
  main: MainReducer,
});

const store = createStore(reducers, enhancer);

export default store;
