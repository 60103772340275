import React from "react";
import "./index.less";
import { Button } from "antd";
import { Link } from "react-router-dom";

interface IProps {
  title?: string;
  icon?: JSX.Element;
  iconPosition?: "left" | "right"
  href?: string;
  onClick?: () => void;
}

const BrandButton: React.FC<IProps> = ({
  title,
  icon,
  href,
  onClick,
  iconPosition = "right",
}) => {
  return href ? (
    <Link to={href}>
      <Button
        className="brand-button"
        type="primary"
        onClick={onClick}
      >
        {iconPosition === "left" && icon}
        {title}
        {iconPosition === "right" && icon}
      </Button>
    </Link>
  ) : (
    <Button
      className="brand-button"
      type="primary"
      onClick={onClick}
    >
      {iconPosition === "left" && icon}
      {title}
      {iconPosition === "right" && icon}
    </Button>
  );
};

export default BrandButton;
