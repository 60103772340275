/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect } from "react";
import {
  Link, useHistory, useLocation,
} from "react-router-dom";
import "./index.less";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import GitexLogoWithText from "../../assets/logo.svg";

interface IProps{

}

const Footer:React.FC<IProps> = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <footer className="footer">
      <div className="footer-content-wrapper">
        <div className="first-row">
          <img
            className="footer-logo"
            src={GitexLogoWithText}
            alt="GitexAmerica"
          />
          <Row>
            <Col span={12}>
              <ul className="footer-sitemap">
                <li className="no-hover"><b>{t("sitemap")}</b></li>
                <li onClick={() => history.push("/")}><Link to="/">{t("home")}</Link></li>
                <li onClick={() => history.push("/about")}><Link to="/about">{t("about")}</Link></li>
                <li onClick={() => history.push("/how-it-works")}>
                  <Link to="/how-it-works">{t("how_it_works")}</Link>
                </li>
                <li onClick={() => history.push("/pricing")}><Link to="/pricing">{t("pricing")}</Link></li>
              </ul>
            </Col>
            <Col span={12}>
              <ul className="footer-sitemap">
                <li onClick={() => history.push("/rewards")}><Link to="/rewards">{t("rewards")}</Link></li>
                <li onClick={() => history.push("/get-started")}><Link to="/get-started">{t("get_started")}</Link></li>
                <li onClick={() => history.push("/faq")}><Link to="/faq">{t("faq")}</Link></li>
                <li onClick={() => history.push("/contact")}><Link to="/contact">{t("contact")}</Link></li>
                <li onClick={() => history.push("/terms")}><Link to="/terms">{t("privacy_policy")}</Link></li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="second-row">
          <div className="copyright">
            {`${moment().format("YYYY")} © Gitex Inc. ${t("all_rights")}`}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
