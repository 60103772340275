import { SET_ACTIVE_SIDER, SET_HEADER_ACTIVE_KEY } from "./Types";

export const setHeaderActiveKey = (menuKey: any) => ({
  type: SET_HEADER_ACTIVE_KEY,
  payload: menuKey,
});

export const setActiveSider = (siderKey: any) => ({
  type: SET_ACTIVE_SIDER,
  payload: siderKey,
});
