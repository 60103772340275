import React from "react";
import "./index.less";
import {
  Col, Layout, Row,
} from "antd";
import { useTranslation } from "react-i18next";
import { colorizeSubstring } from "../../utils/colorizeSubstring";
import SectionBlock from "../../components/SectionBlock";
import { Thtml } from "../../utils/thtml";
import { repeat } from "../../utils/repeat";
import InformationBox from "../../components/InformationBox";

interface IProps{

}

const Pricing:React.FC<IProps> = () => {
  const { t } = useTranslation();
  const renderPricingListSmall = () => {
    return (
      repeat([
        { title: Thtml("pricing_content_1") },
        { title: Thtml("pricing_content_2") },
        { title: Thtml("pricing_content_3") },
        { title: Thtml("pricing_content_5_v") },
        { title: Thtml("pricing_content_10") },
      ], <InformationBox />)
    );
  };
  const renderPricingList = () => {
    return (
      repeat([
        { title: Thtml("pricing_content_1") },
        { title: Thtml("pricing_content_2") },
        { title: Thtml("pricing_content_3") },
        { title: Thtml("pricing_content_4") },
        { title: Thtml("pricing_content_5") },
        { title: Thtml("pricing_content_6") },
        { title: Thtml("pricing_content_7") },
        { title: Thtml("pricing_content_8") },
        { title: Thtml("pricing_content_9") },
        { title: Thtml("pricing_content_10") },
      ], <InformationBox />)
    );
  };
  return (
    <Layout>
      <div className="page-pricing-head">
        <h1>{colorizeSubstring(t("page_pricing_title"), t("colored_gitex_america"))}</h1>
        <h2>{t("unlock_starting_position")}</h2>
      </div>
      <div className="page-pricing-body">
        <Row>
          <Col span={12}>
            <SectionBlock
              header={<h1>{t("pricing_capital")}</h1>}
              title={(
                <h1>
                  <sup>$</sup>
                  6000
                </h1>
              )}
              content={renderPricingListSmall()}
            />
          </Col>
          <Col span={12}>
            <SectionBlock
              header={<h1>{t("pricing_capital")}</h1>}
              title={(
                <h1>
                  <sup>$</sup>
                  15000
                </h1>
              )}
              content={renderPricingList()}
            />
          </Col>
        </Row>
        <p>
          {t("page_pricing_body_1")}
          <br />
          <div>{t("page_pricing_body_1_2")}</div>
        </p>
        <p>
          {t("page_pricing_body_2")}
        </p>
        <p>
          {t("page_pricing_body_3")}
        </p>
        <p>
          {t("page_pricing_body_4")}
        </p>
        <p>
          {t("page_pricing_body_5")}
        </p>
        <p>
          {t("page_pricing_body_6")}
        </p>
      </div>
    </Layout>
  );
};

export default Pricing;
