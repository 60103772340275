/* eslint-disable */
const ENtranslations = {
  home: "Home",
  about: "About Us",
  how_it_works: "How It Works",
  pricing: "Pricing",
  rewards: "Rewards",
  get_started: "Get Started",
  faq: "FAQ's",
  contact: "Contact",
  privacy_policy: "Privacy Policy",
  all_rights: "All rights reserved.",
  sitemap: "S I T E M A P",
  contact_us: "Contact us",
  website_heading: "Start your business in the United States from anywhere",
  colored_business: "business",
  access_the_greatest: "Access the greatest ecosystem on the planet",
  all_in_one_tool: "The only all-in-one incorporation tool.",
  intro_content_1: "Starting a company is a struggle. Lots of paperwork. Outdated software. Government headaches.",
  intro_content_2: "The Gitex Inc. platform is built to fix all of that.",
  intro_step_1: "Register your business",
  intro_step_1_content: "No boring paperwork for you. Register in days—state registration and formation is automated and painless.",
  your_business: "Your Business",
  your_business_loc_1: "720 Sheridan Ave · Cody, WY",
  your_business_loc_2: "671 Lincoln Ave · Wilmington, DE",
  intro_step_2: "Receive your Legal Documents",
  intro_step_2_content: "Forget about legal work or fees. Company formation documents are prepared with standard terms used by top startups.",
  intro_step_2_extra_1: "Operating Agreement",
  intro_step_2_extra_2: "Certificate of Secretary",
  intro_step_2_extra_3: "Indemnification Agreement for Shareholder",
  intro_step_2_extra_4: "Stock Purchase Agreement",
  intro_step_2_extra_5: "Stockholder Approval of Indemnification Agreement",
  intro_step_2_extra_6: "Action by written Consent of Incorporator",
  intro_step_2_extra_7: "Technology Assignment Agreement",
  intro_step_2_extra_8: "Corporate Bylaws",
  intro_step_2_extra_9: "Initial Organizational Resolutions of the Board of Directors",
  intro_step_2_extra_10: "Common Stock Certificate",
  intro_step_3: "Obtain your Tax ID Number",
  intro_step_3_content: "No U.S. citizenship required. Fast tax setup.<br>The only platform with EIN registration to keep you compliant.",
  intro_step_4: "Open your bank account",
  intro_step_4_content: "Banking within reach. Choose a U.S. bank with a debit card, discounts, rewards and more from our exclusive partners.",
  learn_how_it_works: "Learn how it works",
  more_than_just: "More than just incorporation",
  colored_more: "More",
  choosing_gitex_grants: "Choosing Gitex Inc. grants you access to an extensive partnership network.",
  over_150k: "Over $150k in exclusive deals on software & services. Easy invoicing, a physical address in United States, legal consultations and more.",
  access_greatest_startup: "Access the greatest startup ecosystem in the world.",
  before_footer_1: "Supporting founders from anywhere.",
  before_footer_2: "Expert lifetime support in 5 different languages.",
  before_footer_3: "Rewards worth over $150,000 from our exclusive partners.",
  before_footer_4: "No hidden fees.",
  before_footer_5: "Automated bank account opening & compliance.",
  before_footer_6: "Seamless integration with payment processors.",
  before_footer_7: "Automated document generation & stock issuance.",
  your_success: "Start your success story and grow.",
  colored_your: "your",
  start_growing_in_us: "7,000+ companies from over 170 countries have started growing in the U.S. with Gitex Inc.",
  make_dreams_come_true: "We make company growth dreams come true by accelerating company launch times from anywhere ion the planet",
  about_head_title: "We're on a mission to help more companies succeed",
  colored_mission: "mission",
  about_us_1: "Gitex Inc. is building an all-in-one Company OS to help define how founders across the globe launch, manage, and grow their businesses.",
  about_us_2: "More than ten thousand founders from over a hundred countries have used our product, Gitex Inc. started to launch their startup. For many founders, Gitex Inc. has become what they type into their address bars when they think about incorporating a business.",
  about_us_3: "But Gitex Inc. is much more than just incorporation. Our team is dedicated to solving some of the startups’’ hardest problems, with particular focus in:",
  about_us_li_1: "Democratizing access to financial infrastructure",
  about_us_li_2: "Automating mundane tasks every founder faces when running a business",
  about_us_li_3: "Making tools and resources more accessible to everyone, everywhere",
  about_us_li_4: "Building integrated solutions for founders and removing friction",
  about_us_li_5: "Delivering opportunity to traditionally overlooked founders and companies",
  about_us_li_6: "Democratizing access to knowledge",
  page_pricing_title: "Incorporate in the U.S. with Gitex Inc.",
  colored_gitex_america: "Gitex Inc.",
  unlock_starting_position: "with one purchase you will unlock the strongest starting position for your company",
  pricing_capital: "PRICING",
  pricing_content_1: "<b>LLC or C-Corp</b> formation",
  pricing_content_2: "Registration in <b>Wyoming or Delaware</b>",
  pricing_content_3: "<b>TAX ID (EIN)</b> setup with the Internal Revenue Service (IRS)",
  pricing_content_4: "U.S. <b>business bank</b> account setup",
  pricing_content_5: "<b>Physical address</b> in the U.S.",
  pricing_content_5_v: "<b>Virtual address</b> in the U.S.",
  pricing_content_6: "Over <b>$150,000</b> in exclusive deals & perks",
  pricing_content_7: "<b>Free 1 on 1 calls</b> with tax & legal experts",
  pricing_content_8: "<b>Registered agent</b> services",
  pricing_content_9: "Lifetime <b>expert support</b>",
  pricing_content_10: "<b>Post-incorporation</b> documents",
  page_pricing_body_1: "1. We are able to support almost everyone, but unfortunately there are restrictions based on US sanctions and restrictions from our digital banking partners.",
  page_pricing_body_1_2: "Therefore, citizens or residents of the following countries are prohibited from forming a company in the US as a result of US government sanctions and restrictions: Cuba, Iran, North Korea, or Syria. Some of our banking partners also have some residency restrictions for the residents of Belarus, Burundi, Central African Republic, Cuba, Democratic Republic of Congo, Iran, Iraq, Lebanon, Libya, Nicaragua, North Korea, Somalia, South Sudan, Sudan, Syria, Ukraine,Venezuela, Yemen, and Zimbabwe. If you reside in one of these countries, please contact our support with a short description of your company to see if you will be able to get a bank account.",
  page_pricing_body_2: "2. Your US business address will be provided by the registered agent. It’s free and included with the package for both Wyoming and Delaware companies.",
  page_pricing_body_3: "3. We’ll connect you with expert CPAs and attorneys who will help set you on the right course and avoid costly mistakes. Our fee includes a set of free consultations on taxes, immigration and more.",
  page_pricing_body_4: "4. In the US business law, a registered agent is a company or individual designed to receive government notices. We work with reputable registered agents based in Delaware and Wyoming to provide you with exceptional services. The first year fee is built into our pricing.",
  page_pricing_body_5: "5. We are building a tech-enabled, customer-centric service that combines automation and human touch to scale your overall experience. Gitex Inc. provides lifetime support to all of our customers. Our team members will be able to assist you with any of your legal or business needs. Our team members are located around the world and focused on delivering the best customer experience. Our team has successfully helped founders in over 180 countries launch their business.",
  page_pricing_body_6: "6. We automatically, generate a set of essential legal documents after incorporation. Post-Incorporation documents provide clear and necessary information about company owners, operations, and other vital details about the company once incorporation has been completed. All of the documents will be prepared with standard terms used by top startups. Find more information about each generated document Here",
  faq_head_title: "Gitex Inc. FAQ's",
  rewards_head_title: "Rewards for all your needs",
  colored_all: "all",
  rewards_head_subtitle: "Access our exclusive network with perks worth over $150,000 and boost your company.",
  how_it_works_head_title: "It's never been easier to incorporate in the US",
  colored_easier: "easier",
  how_it_works_s1_left: "Submit a simple application with details about your business.",
  how_it_works_s1_right_header_1: "Choose a company type and a state.",
  how_it_works_s1_right_header_2: "The first step is to decide in which state you want your business to incorporate in. Next,the legal entity type within that state. There are benefits to each choice and we've laid out some information below to help you decide. The most common option is an LLC incorporated in Delaware.",
  how_it_works_s1_right_content_1: "LLC. A limited liability company is an organizational business structures in the United States that helps business owners seperate their personal liabilities from the business liabilities.",
  how_it_works_s1_right_content_2: "C-Corporation. A corporation is an independent entity for tax purposes. Corporations generally pay corporate taxes on their own profits,and their shareholders pay personal income on the capital distributed to them.",
  how_it_works_s1_right_content_3: "Delaware. The gold standard for startups planning to raise funding from angel investors and venture capital firms. Most fortune 500 companies are incorporated in Delaware.",
  how_it_works_s1_right_content_4: "Wyoming. Great state for smaller, privately controlled companies. Extremely low cost, very manageable and flexible as your company grows.",
  how_it_works_s2_left: "Sign some forms and we take care of everything.",
  how_it_works_s2_right_header_1: "Focus on your business. We'll handle the details.",
  how_it_works_s2_right_header_2: "Once we receive your application, we start the process by submitting your forms to the relevant state and federal agencies. We'll follow up with you and track the progress daily to ensure you get your application processed as fast as possible. Depending on how quickly the state agencies process the registration, you should receive your paperwork back in as little as a week.",
  how_it_works_s2_right_content_1: "Form 8821. Signing this form authorizes our dedicated team of EIN specialists to get updates on your EIN status and retrieve your EIN letter.",
  how_it_works_s2_right_content_2: "Form SS-4. The Application for Employer Identification Number (SS-4) is the IRS form that we use to apply for an EIN for your new company.",
  how_it_works_s3_left: "Get your bank account for your newly formed company.",
  how_it_works_s3_right_header_1: "Open your account and earn up to 500$ cash back.",
  how_it_works_s3_right_header_2: "We've got you covered. Our full integration with digital banking partners makes the application process completely automated so you won't have to worry about anything. Not even physically being present in the US.",
  how_it_works_s3_right_content_1: "Brex",
  how_it_works_s3_right_content_2: "Mercury",
  how_it_works_s3_right_content_3: "Wise",
  how_it_works_s3_right_content_4: "RelayFi",
  how_it_works_s3_right_content_5: "Payoneer",
  how_it_works_s3_right_content_6: "RHO",
  how_it_works_s4_left: "Get your EIN and all the legal documents you need.",
  how_it_works_s4_right_header_1: "Receive your Tax ID from the IRS in a few days.",
  how_it_works_s4_right_header_2: "The Employer Identification Number (EIN) identifies the company to complete its federal fillings and to open bank accounts. We function as an authorized third party to offer expedited EIN services to customers. You don't need to be a US resident to get your EIN. No SSN or ITIN is required.",
  how_it_works_s4_right_header_3: "All business operations documents ready to go.",
  how_it_works_s4_right_header_4: "We prepare a set of essential legal documents after incorporation. Post-incorporation documents provide clear and necessary information about company owners, operations and other vital details about the company once incorporation has been completed. All of the documents will be prepared with standard terms and used by top startups.",
  how_it_works_s4_right_content_1: "Operating Agreement",
  how_it_works_s4_right_content_2: "Certificate of Secretary",
  how_it_works_s4_right_content_3: "Indemnification Agreement for Shareholder",
  how_it_works_s4_right_content_4: "Stock Purchase Agreement",
  how_it_works_s4_right_content_5: "Stockholder Approval of Indemnification Agreement",
  how_it_works_s4_right_content_6: "Action by written Consent of Incorporator",
  how_it_works_s4_right_content_7: "Technology Assignment Agreement",
  how_it_works_s4_right_content_8: "Corporate Bylaws",
  how_it_works_s4_right_content_9: "Initial Organizational Resolutions of the Board of Directors",
  how_it_works_s4_right_content_10: "Common Stock Certificate",
  how_it_works_s5_left: "Access Exclusive Offers.",
  view_all_rewards: "Explore All Rewards",
  privacy: "Privacy Policy",
  gitex_america: "Gitex Inc.",
  address: "1309 Coffeen Avenue, STE 1200, Sheridan, WY 82801",
  phone_whatsapp: "Phone / Whatsapp",
  email: "Email Address",
  name: "Full Name",
  message: "Message",
  phone: "Phone Number",
  enter_valid_name: "Please enter valid name",
  enter_valid_email: "Please enter valid email",
  enter_valid_message: "Please enter valid message",
  enter_valid_phone: "Please enter phone number",
  enter_valid_state: "Please select state in which you want to register a company",
  i_want_company_in: "I want to register company in: ",
  submit: "Submit",
  ein: "EIN",
  original_id: "Original ID",
  email_sent: "Email was sent successfully",
};

export default ENtranslations;
