import en from "./en";

interface Translations {
  en: any;
}

const translations: Translations = {
  en,
};

export default translations;
