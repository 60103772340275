import React from "react";
import "./index.less";

interface IProps {
  count?: number;
  title?: string;
}

const BlockHeader: React.FC<IProps> = ({ count, title }) => {
  return (
    <div className="block-header">
      {count && (
      <div className="count-wrap">
        {count}
      </div>
      )}
      {title && (
      <div className="title">
        {title}
      </div>
      )}
    </div>
  );
};

export default BlockHeader;
