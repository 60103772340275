import React from "react";
import "./index.less";

interface IProps {
  pre?: string;
  title?: string;
  content?: string;
  icon?: JSX.Element;
}

const InformationBox: React.FC<IProps> = ({
  pre, title, content, icon,
}) => {
  return (
    <div className="information-box">
      {icon && <div className="icon-wrap">{icon}</div>}
      {(pre || content || title) && (
      <div className="content-wrap">
        {pre && <span className="pre">{pre}</span>}
        {title && <span className="title">{title}</span>}
        {content && <span className="content">{content}</span>}
      </div>
      )}
    </div>
  );
};

export default InformationBox;
