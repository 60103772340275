import React from "react";
import "./index.less";

interface IProps {
  list?: any[];
  speed?: number;
  direction?: "left" | "right";
  size?: number;
  shiftX?: number;
}

const IconSlider: React.FC<IProps> = ({
  list, speed = 3, direction = "right", size = 50, shiftX,
}) => {
  return (
    <div className="icon-slider">
      <div
        className="slider-inner"
        style={{
          animationDuration: `${60 / speed}s`,
          animationIterationCount: "infinite",
          animationDirection: direction === "right" ? "normal" : "reverse",
          marginLeft: `-${shiftX ?? 0}px`,
        }}
      >
        {list && list.map((slide) => {
          return (
            <img
              className="slide"
              src={slide}
              alt="slide"
              width={size}
              height={size}
            />
          );
        })}
      </div>
    </div>
  );
};

export default IconSlider;
