import React, { useState } from "react";
import "./index.less";
import {
  Button, Form, Input, Layout, Radio,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  HeatMapOutlined, MailOutlined, NumberOutlined, PhoneOutlined,
} from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import SectionBlock from "../../components/SectionBlock";
import PinLocation from "../../components/PinLocation";
import { sendMail } from "../../utils/sendMail";

interface IProps{

}

const Contact:React.FC<IProps> = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const onFinish = useDebouncedCallback(async (formValues) => {
    sendMail(
      formValues.fullName,
      formValues.email,
      formValues.phone,
      formValues.message,
      formValues.state,
    );
  }, 500);

  return (
    <Layout>
      <div className="form-wrap">
        <SectionBlock
          header={(
            <Form
              name="nest-messages"
              onFinish={onFinish}
            >
              <Form.Item
                name="fullName"
                rules={[{ required: true, message: t("enter_valid_name") }]}
              >
                <Input placeholder={t("name")} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{
                  type: "email", required: true, message: t("enter_valid_email"),
                }]}
              >
                <Input placeholder={t("email")} />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[{
                  required: true, message: t("enter_valid_phone"),
                }]}
              >
                <Input
                  placeholder={t("phone")}
                  type="tel"
                />
              </Form.Item>
              <Form.Item
                name="message"
                rules={[{ required: true, message: t("enter_valid_message") }]}
              >
                <Input.TextArea placeholder={t("message")} />
              </Form.Item>
              <Form.Item
                name="state"
                rules={[{ required: true, message: t("enter_valid_state") }]}
                label={t("i_want_company_in")}
              >
                <Radio.Group
                  options={[
                    { label: "Delaware", value: "delaware" },
                    { label: "Wyoming", value: "wyoming" },
                  ]}
                  onChange={(e:any) => setSelected(e.target.value)}
                  value={selected}
                  optionType="button"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  {t("submit")}
                </Button>
              </Form.Item>
            </Form>
        )}
          content={(
            <>
              <div>
                <PinLocation
                  title={t("gitex_america")}
                  location={t("address")}
                />
              </div>
              <div>
                <PinLocation
                  icon={<MailOutlined />}
                  title={t("email")}
                  location="chief@gitexamerica.com"
                />
              </div>
              <div>
                <PinLocation
                  icon={<PhoneOutlined />}
                  title={t("phone_whatsapp")}
                  location="+1 845 521 7888"
                />
                <PinLocation
                  icon={<NumberOutlined />}
                  title={t("ein")}
                  location="38-4202328"
                />
                <PinLocation
                  icon={<HeatMapOutlined />}
                  title={t("original_id")}
                  location="2021-001053430"
                />
              </div>
            </>
          )}

        />

      </div>
    </Layout>
  );
};

export default Contact;
