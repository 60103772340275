import React from "react";
import "./index.less";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { colorizeSubstring } from "../../utils/colorizeSubstring";
import { repeat } from "../../utils/repeat";
import InformationBox from "../../components/InformationBox";

interface IProps{

}

const About:React.FC<IProps> = () => {
  const { t } = useTranslation();

  const aboutLiList = [
    { title: t("about_us_li_1") },
    { title: t("about_us_li_2") },
    { title: t("about_us_li_3") },
    { title: t("about_us_li_4") },
    { title: t("about_us_li_5") },
    { title: t("about_us_li_6") },
  ];

  return (
    <Layout>
      <div className="about-page-head">
        <h1>{colorizeSubstring(t("about_head_title"), t("colored_mission"))}</h1>
      </div>
      <div className="about-page-body">
        <p>{t("about_us_1")}</p>
        <p>{t("about_us_2")}</p>
        <p>{t("about_us_3")}</p>
        <div className="list-holder">
          {repeat(aboutLiList, <InformationBox />)}
        </div>
      </div>
    </Layout>
  );
};

export default About;
