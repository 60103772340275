import React from "react";
import "./index.less";
import { Layout } from "antd";

interface IProps{

}

const DigitalPresence:React.FC<IProps> = () => {
  return (
    <Layout className="dp_wrapper">
      <div className="notion-header__title-wrapper">
        <h1 className="notion-header__title">
          <span><strong>Building a Digital Presence</strong></span>
        </h1>
        <div
          id="block-faa2a182d5334e74b2d1884716eceb9a"
          className="notion-text"
        >
          <p className="notion-text__content">
            <span className="notion-semantic-string">
              <span>
                <a
                  href="https://gitexamerica.com"
                >
                  gitexamerica.com
                </a>
                {" "}
                <span>
                  is a technology company helping founders, business owners and entrepreneurs incorporate in the US
                  as a legal entity (either a C Corp or LLC). Formally forming your company is often the first step in
                  building a presence for your business, and although
                  {" "}
                </span>
                <a
                  href="https://gitexamerica.com"
                >
                  GitexaAmerica
                </a>
                {" "}
                <span>
                  core service is to legally form your company in either Delaware or Wyoming, we aspire to support
                  each of our customers in building a digital presence, or brand, for your newly formed company.
                </span>
              </span>
            </span>
          </p>
        </div>
        <div
          id="block-1ff129abe0724771914cfd9f6fc5feed"
          className="notion-text"
        >
          <p className="notion-text__content">
            <span
              className="notion-semantic-string"

            >
              Whether you are one of the thousands of founders running foreign-owned US entities or a US citizen,
              banking institutions will require “proof” of your business. Following these 4 basic steps to build a
              digital presence for your business will help in the approval process:
            </span>
          </p>
        </div>
        <ol className="notion-numbered-list">
          <li
            id="block-07fc022c64654688846a3c464aa4de90"
            className="notion-list-item"
          >
            <span
              className="notion-semantic-string"

            >
              Company domain: Once you receive a certificate of incorporation with your company name formally
              registered, you should immediately look to secure a company domain to be used for email exchanges
              related to your business and to host your business website.
            </span>
            <ol className="notion-numbered-list">
              <li
                id="block-09d753df9f864edfb04948fc001dcbbb"
                className="notion-list-item"
              >
                <strong>
                  <span
                    className="notion-semantic-string"
                  >
                    What?
                  </span>
                </strong>
                <ol className="notion-numbered-list">
                  <li
                    id="block-761d038fcb99405fac4b0f90a4463bcb"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"

                    >
                      @gitexamerica.com vs. @gmail.com
                    </span>
                  </li>
                  <li
                    id="block-c5e35e6f9d99427480be0d72ded2f55f"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"

                    >
                      @shopify.com vs. @outlook.com
                    </span>
                  </li>
                  <li
                    id="block-b0215a41de764b3ca0e9696cdecb120b"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"

                    >
                      @yourcompany.com vs. @yahoo.com
                    </span>
                  </li>
                </ol>
              </li>
              <li
                id="block-856353071a3d4646bdfeace5b69a1546"
                className="notion-list-item"
              >
                <strong>
                  <span
                    className="notion-semantic-string"
                  >
                    Why?
                  </span>
                </strong>
                <ol className="notion-numbered-list">
                  <li
                    id="block-0b846bbf5512478b9bc3b2b2c42a1e78"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"

                    >
                      Trust: Obtaining the digital fingerprint for your business is step #1. This fingerprint will
                      build immediate trust with anyone you interact with about your business.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                id="block-53bcd229724e4d0da578521798952363"
                className="notion-list-item"
              >
                <span
                  className="notion-semantic-string"
                >
                  <span>
                    <span>How? Currently,</span>
                    {" "}
                    <a
                      href="https://gitexamerica.com"
                    >
                      gitexamerica.com
                    </a>
                    {" "}
                    <span>
                      does not have a partner to support you with this; however, you can go check out godaddy.com
                      or google.com/domains. We will have a solution for our customers to purchase a company domain.
                    </span>
                  </span>
                </span>
              </li>
            </ol>
          </li>
          <li
            id="block-068c6381b30344c8b0f9fab8ccac9501"
            className="notion-list-item"
          >
            <span
              className="notion-semantic-string"

            >
              Business website
            </span>
            <ol className="notion-numbered-list">
              <li
                id="block-910cfc9ff587497584982deaaa81f6a4"
                className="notion-list-item"
              >
                <span
                  className="notion-semantic-string"
                >
                  What?
                </span>
                <ol className="notion-numbered-list">
                  <li
                    id="block-03f4d614405d42c1a962d2c49a0bd5fc"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"
                    >
                      <span>
                        <a
                          href="https://bril.la/"
                          className="notion-link link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Bril.la
                        </a>
                        {" "}
                      </span>
                    </span>
                  </li>
                  <li
                    id="block-8c693de866e34425bae873acba4bba70"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"
                    >
                      <span>
                        <a
                          href="https://storista.io/"
                          className="notion-link link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Storista.io
                        </a>
                        {" "}
                      </span>
                    </span>
                  </li>
                  <li
                    id="block-8f499b14f10047e1b2723725c89307ab"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"
                    >
                      <span>
                        <a
                          href="https://www.jetboost.io/"
                          className="notion-link link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Jetboost.io
                        </a>
                        {" "}
                      </span>
                    </span>
                  </li>
                </ol>
              </li>
              <li
                id="block-7ee39cbeb0fb4f2bbdae2371385de369"
                className="notion-list-item"
              >
                <strong>
                  <span
                    className="notion-semantic-string"
                  >
                    Why?
                  </span>
                </strong>
                <ol className="notion-numbered-list">
                  <li
                    id="block-a16db60b9b394f8eb7c39ba1efee5862"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"

                    >
                      Legitimacy and Transparency: For a newly formed company, your company domain is the digital name
                      tag for your business. Mapping your company domain to not only your email address, but also a
                      live website with helpful content is a given.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                id="block-628763efbb6a44a9ad32d7d86c930f16"
                className="notion-list-item"
              >
                <span
                  className="notion-semantic-string"
                >
                  <span>
                    <span>
                      How? Currently, you can use the
                    </span>
                    {" "}
                    <a
                      href="https://gitexamerica.com"
                    >
                      GitexAmerica
                    </a>
                    {" "}
                  </span>
                  <span>
                    partners,
                  </span>
                  <span>
                    {" "}
                    <a
                      href="https://carrd.co/"
                      className="notion-link link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      carrd.co
                    </a>
                  </span>
                  <span>
                    ,
                    {" "}
                    <a
                      href="https://zyro.com/"
                    >
                      zyro.com
                    </a>
                    , or
                    {" "}
                    <a href="https://versoly.com/">versoly.com</a>
                    {" "}
                    <span>
                      to setup a quick, professional website for your business. You can find information about each of
                      these partners and the discounts we can offer for them within your
                    </span>
                    {" "}
                    <a
                      href="https://gitexamerica.com"
                    >
                      gitexamerica.com
                    </a>
                    {" "}
                    <span>Account</span>
                  </span>
                </span>
              </li>
            </ol>
          </li>
          <li
            id="block-a28d8e0a008a4223aec073bac11b2602"
            className="notion-list-item"
          >
            <span>
              <span className="notion-semantic-string">LinkedIn/Angel.co Business profile and social media&nbsp;</span>
              presence.
            </span>
            <ol className="notion-numbered-list" />
            <ol className="notion-numbered-list">
              <li
                id="block-2d4d49d9cd0b43c0b30dc237c5304b71"
                className="notion-list-item"
              >
                <span
                  className="notion-semantic-string"
                >
                  Brand Awareness: By publicly sharing your business on legitimate digital communities provide further
                  validation of your business and brand.
                </span>
              </li>
              <li
                id="block-7d8b0085e64c4e9b82356a214d9bfcce"
                className="notion-list-item"
              >
                <span className="notion-semantic-string">
                  All of these profiles are free and very easy to step.
                  Go directly to these sites to set up your profile and start starting and engaging with the community.
                </span>
              </li>
            </ol>
          </li>
          <li className="notion-list-item">
            <span
              className="notion-semantic-string"

            >
              Blog posts and published articles
            </span>
            <ol className="notion-numbered-list">
              <li
                id="block-7880d84bfc504311ab34f9cfa7e3d88c"
                className="notion-list-item"
              >
                <span>
                  <strong><span className="notion-semantic-string">Why?</span></strong>
                </span>
                <ol className="notion-numbered-list">
                  <li
                    id="block-9f9bd35844d14da08e6e7feae9ca37ee"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"

                    >
                      Reputation: Whether starting your own blog or publishing on third-party sites, the content you
                      create and share leaves footprints of where you’ve been and the influence/expertise you bring
                      to an industry or group.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                id="block-068ce6e76ac4472e9dd5a5a6194f6bb5"
                className="notion-list-item"
              >
                <span>
                  <strong><span className="notion-semantic-string">How?</span></strong>
                </span>
                <ol className="notion-numbered-list">
                  <li
                    id="block-96b56c5e2e584ed88ae58ddeb8ab516b"
                    className="notion-list-item"
                  >
                    <span
                      className="notion-semantic-string"
                    >
                      <span>
                        <span>Currently,</span>
                        {" "}
                        <a
                          href="https://gitexamerica.com"
                        >
                          gitexamerica.com
                        </a>
                        {" "}
                        <span>
                          doesn’t have partners to help publish your articles on third-party sites, but you can
                          always start a blog on Medium.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li className="notion-list-item">
                    <span>
                      Please note that we are currently building out a more valuable resource library to help with
                      these specific steps, but in the meantime, we hope that this simple guide will support your
                      journey in building a digital presence for your business.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <div
          id="block-60631ab394c64f8b86162d74447701f4"
          className="notion-text"
        >
          <p className="notion-text__content"><span className="notion-semantic-string"><span /></span></p>
        </div>
        <div
          id="block-5b059c868ed24d589bd48496591e906b"
          className="notion-text"
        >
          <p className="notion-text__content">
            <span
              className="notion-semantic-string"

            >
              Thanks!
            </span>
          </p>
        </div>
        <div
          id="block-827278f23ac7427cab18def18ea1d3ea"
          className="notion-text"
        >
          <p className="notion-text__content">
            <span className="notion-semantic-string">
              <span>
                <span>
                  Feel free to reach out to
                </span>
                {" "}
                <strong>
                  <span>
                    <a
                      href="mailto:chief@gitexamerica.com"
                    >
                      chief@gitexamerica.com
                    </a>
                  </span>
                </strong>
              </span>
              <span>
                <span>
                  {" "}
                  if you are ready to incorporate your business, or if you are already formed your business with
                </span>
                {" "}
                <a
                  href="https://gitexamerica.com"
                >
                  gitexamerica.com
                </a>
                {" "}
                <span>and have questions about building a digital presence, feel free to send us a note at</span>
                {" "}
                <strong>
                  <span>
                    <a
                      href="mailto:chief@gitexamerica.com"
                    >
                      chief@gitexamerica.com
                    </a>
                  </span>
                </strong>
              </span>
              <span>.</span>
            </span>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default DigitalPresence;
