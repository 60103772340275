import React from "react";
import "./index.less";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import terms from "./terms.json";

interface IProps {

}

const Rewards: React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <Layout className="rewards-wrapper">
      <div className="rewards-page-head">
        <h1>{t("privacy")}</h1>
      </div>

      <div dangerouslySetInnerHTML={{ __html: terms.en_data }} />
    </Layout>
  );
};

export default Rewards;
