import React from "react";
import "./index.less";
import { Layout } from "antd";
// @ts-ignore
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";
import { useTranslation } from "react-i18next";
import SectionBlock from "../../components/SectionBlock";
import { colorizeSubstring } from "../../utils/colorizeSubstring";
import InformationBox from "../../components/InformationBox";
import Brex from "../../assets/images/slider/slider1_1.svg";
import Wise from "../../assets/images/slider/slider1_6.svg";
import RelayFi from "../../assets/images/slider/slider1_10.svg";
import Mercury from "../../assets/images/slider/slider2_5.svg";
import Payoneer from "../../assets/images/slider/slider3_4.svg";
import Rho from "../../assets/images/slider/slider3_6.svg";
import IRS from "../../assets/images/IRS.png";
import Reward1 from "../../assets/images/slider/slider2_1.svg";
import Reward2 from "../../assets/images/slider/slider2_2.svg";
import Reward3 from "../../assets/images/slider/slider2_3.svg";
import Reward4 from "../../assets/images/slider/slider2_4.svg";
import Reward5 from "../../assets/images/slider/slider1_5.svg";
import Reward6 from "../../assets/images/slider/slider2_6.svg";
import Reward7 from "../../assets/images/slider/slider2_7.svg";
import Reward8 from "../../assets/images/slider/slider2_8.svg";
import Reward9 from "../../assets/images/slider/slider2_9.svg";
import Reward10 from "../../assets/images/slider/slider2_10.svg";
import Reward11 from "../../assets/images/slider/slider2_11.svg";
import Reward12 from "../../assets/images/slider/slider2_12.svg";
import Reward13 from "../../assets/images/slider/slider2_13.svg";

import BrandButton from "../../components/BrandButton";

interface IProps{

}

const HowItWorks:React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="how-it-works-page-head">
        <h1>{colorizeSubstring(t("how_it_works_head_title"), t("colored_easier"))}</h1>
      </div>
      <Timeline lineColor="#ddd">
        <TimelineItem
          key="001"
          dateComponent={(
            <div
              style={{
                display: "block",
                float: "left",
                padding: "10px",
                fontSize: "27px",
                width: "100%",
                color: "#fff",
              }}
            >
              {t("how_it_works_s1_left")}
            </div>
          )}
          dateInnerStyle={{ background: "#61b8ff", color: "#000" }}
        >
          <SectionBlock
            header={(
              <>
                <h3>{t("how_it_works_s1_right_header_1")}</h3>
                <p>
                  {t("how_it_works_s1_right_header_2")}
                </p>
              </>
            )}
            content={(
              <>
                <InformationBox content={t("how_it_works_s1_right_content_1")} />
                <InformationBox content={t("how_it_works_s1_right_content_2")} />
                <InformationBox content={t("how_it_works_s1_right_content_3")} />
                <InformationBox content={t("how_it_works_s1_right_content_4")} />
              </>
            )}
          />
        </TimelineItem>

        <TimelineItem
          key="002"
          dateComponent={(
            <div
              style={{
                display: "block",
                float: "left",
                padding: "10px",
                fontSize: "27px",
                width: "100%",
                color: "#fff",
              }}
            >
              {t("how_it_works_s2_left")}
            </div>
          )}
          dateInnerStyle={{ background: "#61b8ff", color: "#000" }}
        >
          <SectionBlock
            header={(
              <>
                <h3>{t("how_it_works_s2_right_header_1")}</h3>
                <p>
                  {t("how_it_works_s2_right_header_2")}
                </p>
              </>
            )}
            content={(
              <>
                <InformationBox content={t("how_it_works_s2_right_content_1")} />
                <InformationBox content={t("how_it_works_s2_right_content_2")} />
              </>
            )}
          />
        </TimelineItem>

        <TimelineItem
          key="003"
          dateComponent={(
            <div
              style={{
                display: "block",
                float: "left",
                padding: "10px",
                fontSize: "27px",
                width: "100%",
                color: "#fff",
              }}
            >
              {t("how_it_works_s3_left")}
            </div>
          )}
          dateInnerStyle={{ background: "#61b8ff", color: "#000" }}
        >
          <SectionBlock
            header={(
              <>
                <h3>{t("how_it_works_s3_right_header_1")}</h3>
                <p>
                  {t("how_it_works_s3_right_header_2")}
                </p>
              </>
            )}
            content={(
              <div className="flex-box-timeline">
                <InformationBox
                  icon={(
                    <img
                      src={Brex}
                      alt="brex"
                    />
                  )}
                  content={t("how_it_works_s3_right_content_1")}
                />
                <InformationBox
                  icon={(
                    <img
                      src={Mercury}
                      alt="Mercury"
                    />
                  )}
                  content={t("how_it_works_s3_right_content_2")}
                />
                <InformationBox
                  icon={(
                    <img
                      src={Wise}
                      alt="Wise"
                    />
                  )}
                  content={t("how_it_works_s3_right_content_3")}
                />
                <InformationBox
                  icon={(
                    <img
                      src={RelayFi}
                      alt="RelayFi"
                    />
                  )}
                  content={t("how_it_works_s3_right_content_4")}
                />
                <InformationBox
                  icon={(
                    <img
                      src={Payoneer}
                      alt="Payoneer"
                    />
                  )}
                  content={t("how_it_works_s3_right_content_5")}
                />
                <InformationBox
                  icon={(
                    <img
                      src={Rho}
                      alt="Rho"
                    />
                  )}
                  content={t("how_it_works_s3_right_content_6")}
                />
              </div>
            )}
          />
        </TimelineItem>

        <TimelineItem
          key="004"
          dateComponent={(
            <div
              style={{
                display: "block",
                float: "left",
                padding: "10px",
                fontSize: "27px",
                width: "100%",
                color: "#fff",
              }}
            >
              {t("how_it_works_s4_left")}
            </div>
          )}
          dateInnerStyle={{ background: "#61b8ff", color: "#000" }}
        >
          <>
            <SectionBlock
              header={(
                <>
                  <h3>{t("how_it_works_s4_right_header_1")}</h3>
                  <p>
                    {t("how_it_works_s4_right_header_2")}
                  </p>
                </>
            )}
              content={(
                <img
                  src={IRS}
                  alt="IRS"
                />
            )}
            />
            <SectionBlock
              header={(
                <>
                  <h3>{t("how_it_works_s4_right_header_3")}</h3>
                  <p>
                    {t("how_it_works_s4_right_header_4")}
                  </p>
                </>
              )}
              content={(
                <div className="flex-box-timeline">
                  <InformationBox content={t("how_it_works_s4_right_content_1")} />
                  <InformationBox content={t("how_it_works_s4_right_content_2")} />
                  <InformationBox content={t("how_it_works_s4_right_content_3")} />
                  <InformationBox content={t("how_it_works_s4_right_content_4")} />
                  <InformationBox content={t("how_it_works_s4_right_content_5")} />
                  <InformationBox content={t("how_it_works_s4_right_content_6")} />
                  <InformationBox content={t("how_it_works_s4_right_content_7")} />
                  <InformationBox content={t("how_it_works_s4_right_content_8")} />
                  <InformationBox content={t("how_it_works_s4_right_content_9")} />
                  <InformationBox content={t("how_it_works_s4_right_content_10")} />
                </div>
              )}
            />
          </>
        </TimelineItem>

        <TimelineItem
          key="005"
          dateComponent={(
            <div
              style={{
                display: "block",
                float: "left",
                padding: "10px",
                fontSize: "27px",
                width: "100%",
                color: "#fff",
              }}
            >
              {t("how_it_works_s5_left")}
            </div>
          )}
          dateInnerStyle={{ background: "#61b8ff", color: "#000" }}
        >
          <>
            <SectionBlock
              content={(
                <div className="flex-box-timeline">
                  <InformationBox
                    icon={(
                      <img
                        src={Reward1}
                        alt="Reward1"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward2}
                        alt="Reward2"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward3}
                        alt="Reward3"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward4}
                        alt="Reward4"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward5}
                        alt="Reward5"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward6}
                        alt="Reward6"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward7}
                        alt="Reward7"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward8}
                        alt="Reward8"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward9}
                        alt="Reward9"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward10}
                        alt="Reward10"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward11}
                        alt="Reward11"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward12}
                        alt="Reward12"
                      />
                    )}
                  />
                  <InformationBox
                    icon={(
                      <img
                        src={Reward13}
                        alt="Reward13"
                      />
                    )}
                  />
                </div>
              )}
            />

            <div className="center">
              <BrandButton
                title={t("view_all_rewards")}
                onClick={() => window.scroll(0, 0)}
                href="/rewards"
              />
            </div>
          </>
        </TimelineItem>
      </Timeline>
    </Layout>
  );
};

export default HowItWorks;
