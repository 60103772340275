import React from "react";
import {
  Route, Router, Switch,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "../layouts/home";
import About from "../layouts/about";
import HowItWorks from "../layouts/how_it_works";
import Pricing from "../layouts/pricing";
import Rewards from "../layouts/rewards";
import Contact from "../layouts/contact";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DigitalPresence from "../layouts/digital_presence";
import FAQ from "../layouts/faq";
import Terms from "../layouts/terms";

const history = createBrowserHistory();

const AppRouter = () => {
  return (
    <Router history={history}>

      <div className="main-page">
        <Header />
        <Switch>
          <Route
            path="/"
            exact
          >
            <Home />
          </Route>
          <Route
            path="/about"
            exact
          >
            <About />
          </Route>
          <Route
            path="/how-it-works"
            exact
          >
            <HowItWorks />
          </Route>
          <Route
            path="/pricing"
            exact
          >
            <Pricing />
          </Route>
          <Route
            path="/Rewards"
            exact
          >
            <Rewards />
          </Route>
          <Route
            path="/get-started"
            exact
          >
            <Contact />
          </Route>
          <Route
            path="/faq"
            exact
          >
            <FAQ />
          </Route>
          <Route
            path="/contact"
            exact
          >
            <Contact />
          </Route>
          <Route
            path="/digital-presence"
            exact
          >
            <DigitalPresence />
          </Route>
          <Route
            path="/terms"
            exact
          >
            <Terms />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default AppRouter;
